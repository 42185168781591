export const AdminPrivateLocationRoutes = id => ({
  edit: `/locations/${id}/edit`,
  show: `/locations/${id}`,
  destroy: `/locations/${id}`,
  index: '/locations',
  new: '/locations/new',
  instructions: `/locations/${id}/instructions`
})

export const LoginApiRoutes = () => ({
  new: `/user_session/new`,
  create: `/user_session`,
  delete: `/user_session`,
  sign_out: `/sign_out`,
  auth_type: '/user_session/auth_type'
})

export const ForgotPasswordRoutes = () => ({
  create: `/accounts/forgot-password`
})

export const WidgetApiRoutes = id => ({
  index: '/api/v2/widgets',
  create: '/api/v2/widgets',
  show: `/api/v2/widgets/${id}`,
  update: `/api/v2/widgets/${id}`,
  destroy: `/api/v2/widgets/${id}`
})

export const FilmstripRoutes = (checkId, runId) => ({
  retrieve_filmstrips: `/checks/${checkId}/runs/${runId}/screenshots/retrieve_filmstrips.json`,
  retrieve_filmstrips_video: `/checks/${checkId}/runs/${runId}/videos/retrieve_filmstrips_video.json`
})

export const AdminIntegrationRoutes = id => ({
  edit: `/admin/integrations/${id}/edit`,
  destroy: `/admin/integrations/${id}`,
  index: '/admin/integrations',
  new: '/admin/integrations',
  show: `/admin/integrations/${id}`
})

export const IntegrationAPIRoutes = id => ({
  update: `/api/v2/admin/integrations/${id}.json`,
  show: `/api/v2/admin/integrations/${id}.json`,
  edit: `/api/v2/admin/integrations/${id}`,
  index: `/api/v2/admin/integrations`,
  create: `/api/v2/admin/integrations`
})

// returns correct routing for a check based on type: real_browser -> real_browsers
export const checkTypeToPath = {
  api: 'apis',
  benchmark: 'benchmarks',
  content: 'contents',
  network: 'ports',
  real_browser: 'real-browsers',
  http: 'http',
  response: 'http',
  http_multi_step: 'http-multi'
}

export const CheckRoutes = (id, type) => {
  const path = checkTypeToPath[type]
  return {
    edit: `/checks/${path}/${id}/edit`,
    destroy: `/checks/${path}/${id}`,
    index: `/checks/${path}`,
    new: `/checks/${path}/new`,
    show: `/checks/${path}/${id}`,
    mute: `/checks/${id}/toggle_muted`,
    pause: `/checks/${id}/toggle_enabled`,
    run: `/checks/${id}/manual_run.json`,
    copy: `/checks/${id}/copy/new`,
    performance_kpis: `/checks/${path}/${id}/performance_kpis`, // rbc checks only
    performance: `/checks/${path}/${id}/performance`, // uptime checks only
    uptime: `/checks/${path}/${id}/uptime`,
    trends: `/checks/${path}/${id}/trends`
  }
}

export const CheckRunHistoryRoutes = (checkId, id) => ({
  show: `/checks/${checkId}/runs/${id}`
})

export const ReportRoutes = checkId => ({
  domain_performance: `/reports/domain_performance/${checkId}`
})

export const TagRoutes = id => ({
  show: `/tags/${id}`
})

export const AdminBulkChangesRoutes = () => ({
  create: '/admin/bulk_changes'
})

export const ApiV2LocationRoutes = id => ({
  index: '/api/v2/locations',
  show: `/api/v2/locations/${id}`,
  agents: `/api/v2/locations/${id}/agents`,
  queues: `/api/v2/locations/${id}/queues`,
  checks: `/api/v2/locations/${id}/checks`
})

export const ApiV2CheckRoutes = id => ({
  index: '/api/v2/checks',
  show: `/api/v2/checks/${id}`
})

export const ApiV2RealBrowserCheckRoutes = id => ({
  export: `/api/v2/checks/real_browsers/${id}/export.json`,
  exportSide: `/api/v2/checks/real_browsers/${id}/export.side`,
  exportRuby: `/api/v2/checks/real_browsers/${id}/export.rb`
})

export const DashboardRoutes = (type, id) => ({
  index: `/dashboards/${type}`,
  show: `/dashboards/${type}/${id}`,
  edit: `/dashboards/${type}/${id}/edit`,
  destroy: `/dashboards/${type}/${id}`,
  copy: `/dashboards/${type}/${id}/copy`
})

export const DashboardPerformanceKPIRoute = (dashboardId, type = 'executive') =>
  `/dashboards/${type}/${dashboardId}/performance_kpis.json`

export const PanelOptionsRoute = () => '/api/v2/panels/options.json'
export const PanelsPerformanceKPIRoute = () => '/performance_kpis.json'
export const ReportsPerformanceKPIRoute = () => '/api/v2/metrics/data.json'

// To return multiple check metrics, pass an "other_check_ids" param with an array of check ids
// Pass a random rbc id as the realBrowserId below to be valid when using "other_check_ids"
export const PerformanceKPIRoutes = realBrowserId => ({
  index: `/checks/real-browsers/${realBrowserId}/performance_kpis`
})

export const AuditsRoutes = () => ({
  index: `/admin/audits`
})

export const OptimizationAnalysesRoutes = runId => ({
  create: `/runs/${runId}/optimization_analyses`
})

export const TestSuiteApiRoutes = id => ({
  index: '/api/v2/test_suites',
  create: '/api/v2/test_suites',
  show: `/api/v2/test_suites/${id}`,
  update: `/api/v2/test_suites/${id}`,
  destroy: `/api/v2/test_suites/${id}`
})

export const TestSuiteRunApiRoutes = (testSuiteId, id) => ({
  index: `/api/v2/test_suites/${testSuiteId}/runs`,
  create: `/api/v2/test_suites/${testSuiteId}/runs`,
  show: `/api/v2/test_suites/${testSuiteId}/runs/${id}`
})

export const TestSuiteRoutes = id => ({
  index: '/test_suites',
  new: '/test_suites/new',
  show: `/test_suites/${id}`,
  edit: `/test_suites/${id}/edit`
})

export const RecipientsRoutes = () => '/scheduled_reports/recipients_list.json'

export const TimezonesRoutes = () => '/timezones.json'

export const CustomReportsRoutes = id => ({
  index: '/reports',
  show: `/reports/${id}`,
  update: `/reports/${id}/panels.json`,
  destroy: `/reports/${id}.json`
})

export const CustomReportsApiRoute = '/api/v2/reports'
export const CustomReportApiRoute = reportId => `${CustomReportsApiRoute}/${reportId}`
export const CopyCustomReportApiRoute = reportId => `${CustomReportsApiRoute}/${reportId}/copy`
export const ExportCustomReportApiRoute = reportId => `${CustomReportsApiRoute}/${reportId}/export`
export const BulkDeleteCustomReportApiRoute = `${CustomReportsApiRoute}/bulk.json`
export const MergeCustomReportApiRoute = `${CustomReportsApiRoute}/bulk/merge.json`

export const PanelApiRoutes = panelId => `/api/v2/panels/${panelId}`
export const CopyPanelApiRoutes = panelId => `/api/v2/panels/${panelId}/copy`
