import React, { useState } from 'react'
import Text from '@splunk/react-ui/Text'
import Button from '@splunk/react-ui/Button'
import Message from '@splunk/react-ui/Message'
import PropTypes from 'prop-types'
import { resetPassword } from '../utils/action'
import './ForgotPassword.scss'

let email = ''

const verifyEmail = () => {
  if (email.indexOf('@') === -1 || email.indexOf('@') === email.length || email.length < 1) {
    return false
  }

  return true
}

const submitResetPasword = async (setShowPasswordReset, setPasswordResetMessage, setEmailError) => {
  if (!verifyEmail()) {
    setEmailError(true)
    return
  }

  try {
    const response = await resetPassword({ user: { email: email } })
    setPasswordResetMessage(response.data.message)
    setShowPasswordReset(true)
  } catch (error) {
    const message = error && error.response && error.response.data && error.response.data.message || 'An error has occurred. Please try again.'
    setPasswordResetMessage(message)
    setShowPasswordReset(true)
  }
}

const ForgotPassword = ({ toggleForgotPassword }) => {
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const [passwordResetMessage, setPasswordResetMessage] = useState('')
  const [emailError, setEmailError] = useState(false)

  const setEmail = (e, { value }) => {
    setEmailError(false)
    email = value
  }

  return (
    <div>
      {showPasswordReset && (
        <Message appearance='fill' type='info' data-testid='passwordResetMessage'>
          {passwordResetMessage}
        </Message>
      )}
      {!showPasswordReset && emailError && (
        <Message appearance='fill' type='error' data-testid='emailErrorMessage'>
          {'Please enter a valid email address.'}
        </Message>
      )}
      <div className='forgot-password-text' data-testid='forgotPasswordText'>
        Please enter your email to request a password reset.
      </div>
      <div className='forgot-password-input' data-testid='forgotPasswordInput'>
        <Text error={emailError} onChange={setEmail} placeholder='Email' canClear />
      </div>
      <div data-testid='resetPasswordButton'>
        <Button
          onClick={() => submitResetPasword(setShowPasswordReset, setPasswordResetMessage, setEmailError)}
          label='RESET PASSWORD'
          appearance='primary'
          className='reset-password-button'
        />
      </div>

      <div className='go-back-text' data-testid='goBackText' onClick={() => toggleForgotPassword(false)}>
        ← Back to other login options
      </div>
    </div>
  )
}

ForgotPassword.propTypes = {
  toggleForgotPassword: PropTypes.func
}

export default React.memo(ForgotPassword)
