import React, { useState } from 'react'
import LoginForm from '../form/LoginForm'
import TabBar from '@splunk/react-ui/TabBar'
import ForgotPassword from '../forgotPassword/ForgotPassword'
import PropTypes from 'prop-types'

import './LoginContainer.scss'

const productTabs = redirectUrl => {
  return (
    <TabBar activeTabId={'synthetics'} onChange={(e, { value }) => navigateToOptimization(e, { value }, redirectUrl)}>
      <TabBar.Tab data-testid='syntheticsTab' className='app-tab' label='Synthetic Monitoring' tabId='synthetics' />
      <TabBar.Tab data-testid='optimizationTab' className='app-tab' label='Web Optimization' tabId='optimization' />
    </TabBar>
  )
}

const navigateToOptimization = (e, { selectedTabId }, redirectUrl) => {
  window.location.href = redirectUrl
}

const LoginContainer = ({ logoImage, optimizationAppUrl }) => {
  const [forgotPassword, setForgotPassword] = useState(false)

  return (
    <div className='login-page'>
      <div className='login-container' data-testid='loginContainer'>
        <div>
          {forgotPassword ? (
            <ForgotPassword toggleForgotPassword={setForgotPassword} />
          ) : (
            <div className='login-form'>
              <img src={logoImage} className='login-logo' data-testid='loginLogo' />
              <div className='app-tabs' data-testid='productTabs'>
                {productTabs(optimizationAppUrl)}
              </div>
              <LoginForm toggleForgotPassword={setForgotPassword} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

LoginContainer.propTypes = {
  optimizationAppUrl: PropTypes.string.isRequired,
  logoImage: PropTypes.string.isRequired
}

export default React.memo(LoginContainer)
