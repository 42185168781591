import axios from 'axios'
import { LoginApiRoutes, ForgotPasswordRoutes } from '../../shared/routesUtils'

export const login = userSession => {
  return axios.post(`${LoginApiRoutes().create}`, userSession, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })
}

export const resetPassword = email => {
  return axios.post(`${ForgotPasswordRoutes().create}`, email, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })
}

export const handleKeyupListener = listener => {
  window.addEventListener('keyup', listener)

  return () => {
    window.removeEventListener('keyup', listener)
  }
}
