import React from 'react'
import ReactDOM from 'react-dom'
import LoginContainer from '../../login/container/LoginContainer'

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('login-page')
  const optimizationAppUrl = node.getAttribute('optimization-app-url')
  const logoImage = node.getAttribute('logo-image')

  ReactDOM.render(<LoginContainer logoImage={logoImage} optimizationAppUrl={optimizationAppUrl} />, node)
})
