import React, { useState, useCallback, useEffect } from 'react'
import Text from '@splunk/react-ui/Text'
import Button from '@splunk/react-ui/Button'
import PropTypes from 'prop-types'
import Message from '@splunk/react-ui/Message'
import axios from 'axios'
import { login, handleKeyupListener } from '../utils/action'
import { LoginApiRoutes } from '../../shared/routesUtils'

import './LoginForm.scss'

const errorMessages = {
  signInFailed: 'Sign in failed. Incorrect username or password.',
  missingCredentials: 'Please enter a username and password to sign in.'
}

let userSession = { login: '', password: '', remember_me: false }
let errorMessage = errorMessages.signInFailed

const setUsername = (setLoginError, _e, { value }) => {
  userSession.login = value
  setLoginError(false)
}

const setPassword = (setLoginError, _e, { value }) => {
  userSession.password = value
  setLoginError(false)
}

const submitLogin = async setLoginError => {
  if (userSession.login.length < 1 || userSession.password.length < 1) {
    errorMessage = errorMessages.missingCredentials
    setLoginError(true)
    return
  }

  try {
    const redirectPath = await login({ user_session: userSession })
    setLoginError(false)
    window.location.replace(window.location.href.replace(/\/$/, '') + redirectPath.data)
  } catch (error) {
    errorMessage = errorMessages.signInFailed
    setLoginError(true)
  }
}

const checkSamlUser = async (setShowNext) => {
  const authTypeUrl = `${LoginApiRoutes().auth_type}?username=${userSession.login}`
  const headers = {
    'Content-Type': 'application/json'
  }

  return axios.get(authTypeUrl, { headers: headers }).then(response => {
    if (response.data.redirect_to) {
      window.location = response.data.redirect_to
    } else {
      setShowNext(false)
      document.getElementById('password').focus()
    }
  }).catch(() => {
    setShowNext(false)
  })
}

const handleUsernameFocus = (showNext, setShowNext) => {
  if (!showNext) {
    setShowNext(true)
  }
}

const enterKeyPressed = (event) => {
  return event && event.keyCode && event.keyCode === 13
}

const reEnterUsername = (setShowNext) => {
  setShowNext(true)
}

const LoginForm = ({ toggleForgotPassword }) => {
  const [loginError, setLoginError] = useState(false)
  const [showNext, setShowNext] = useState(true)

  const autoLoginListener = useCallback(event => {
    if (enterKeyPressed(event)) {
      submitLogin(setLoginError)
    }
  }, [])

  const checkSamlUserListener = useCallback(event => {
    if (enterKeyPressed(event) && userSession.login.length > 1 && userSession.password.length < 1) {
      checkSamlUser(setShowNext)
    }
  }, [])

  useEffect(() => {
    if (showNext) {
      handleKeyupListener(checkSamlUserListener) // click the `NEXT` button
    } else if (!showNext) {
      // we shouldn't need to check `isSamlUser` because the page should redirect to the SAML login.
      // This is mainly an extra check for edge case protection
      handleKeyupListener(autoLoginListener) // click the `SIGN IN` button
    }
  }, [checkSamlUserListener, autoLoginListener, showNext])

  return (
    <div className='login-form' data-testid='loginForm'>
      <div className='username-field form-field' data-testid='usernameField'>
        <Text
          inputId='login'
          onChange={(e, { value }) => setUsername(setLoginError, e, { value })}
          onFocus={() => handleUsernameFocus(showNext, setShowNext)}
          placeholder='Username'
          data-testid='username'
          canClear
        />
      </div>
      {showNext && <Button
        onClick={() => checkSamlUser(setShowNext)}
        label='NEXT'
        appearance='primary'
        className='login-button'
        data-testid='nextButton' />}
      {!showNext && <div>
        <div className='password-field form-field' data-testid='passwordField'>
          <Text
            inputId='password'
            onChange={(e, { value }) => setPassword(setLoginError, e, { value })}
            placeholder='Password'
            type='password'
            data-testid='password'
            canClear
          />
        </div>
        {loginError && (
          <div className='login-error-message' data-testid='loginErrorMessage'>
            <Message className='error-message' appearance='fill' type='error'>
              {errorMessage}
            </Message>
          </div>
        )}
        <div className='submit' data-testid='loginButton'>
          <Button
            onClick={() => submitLogin(setLoginError)}
            label='SIGN IN'
            appearance='primary'
            className='login-button'
          />
        </div>
      </div>}
      <div onClick={() => toggleForgotPassword(true)} className='forgot-password-link' data-testid='forgotPasswordLink'>
        Forgot password?
      </div>
    </div>
  )
}

LoginForm.propTypes = {
  toggleForgotPassword: PropTypes.func
}

export default React.memo(LoginForm)
